import {createApp, h} from "vue";
// @ts-ignore
import NProgress from 'nprogress';
import {createInertiaApp, Link, router} from "@inertiajs/vue3";
// @ts-ignore
import {modal, ModalPluginOptions} from "momentum-modal";
import * as Sentry from "@sentry/vue";

// @ts-ignore
window.inertiaEventsCount = {
    navigateCount: 0,
    successCount: 0,
    errorCount: 0,
};

// @ts-ignore
import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

router.on('start', () => NProgress.start());
router.on('finish', () => NProgress.done());

function resolvePageComponent(name: string, pages: Record<string, any>) {
    for (const path in pages) {
        if (name && path.endsWith(`${name.replace(".", "/")}.vue`)) {
            return typeof pages[path] === "function" ? pages[path]() : pages[path]
        }
    }

    throw new Error(`Page not found: ${name}`)
}

const app = createInertiaApp({
        setup({el, App, props, plugin}) {
            const app = createApp({
                render: () => h(App, props), mounted() {
                    this.$inertia.on('navigate', (event: any) => {
                        // @ts-ignore
                        window.inertiaEventsCount.navigateCount++;
                    });

                    this.$inertia.on('success', (event: any) => {
                        // @ts-ignore
                        window.inertiaEventsCount.successCount++;
                    });

                    this.$inertia.on('error', (event: any) => {
                        // @ts-ignore
                        window.inertiaEventsCount.errorCount++;
                    });
                },
            })
                .use(modal, {
                    // @ts-ignore
                    resolve: (name: string) => resolvePageComponent(name, import.meta.glob("./Pages/**/*.vue")),
                } as ModalPluginOptions)
                .use(plugin)
                .component('InertiaLink', Link);

            Sentry.init({
                app,
                tracePropagationTargets: ['stage.mi-boda.com', 'mi-boda.com', /^\//],
                environment: import.meta.env.VITE_SENTRY_ENV,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                sendDefaultPii: true,
                denyUrls: [
                    /extensions\//i,
                    /^safari-extension:\/\//i,
                    /^safari-web-extension:\/\//i,
                    /^moz-extension:\/\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    /moz-extension/i
                ],
                ignoreErrors: [
                    "@webkit-masked-url",
                    "debugger eval",
                    "Not implemented",
                    "top.GLOBALS"
                ]
            });

            if (el) {
                app.mount(el);
            }
        },
        // @ts-ignore
        progress: false,
        // @ts-ignore
        resolve: (name) => resolvePageComponent(name, import.meta.glob("./Pages/**/*.vue")),
    })
;
